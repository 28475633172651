import { Typography, Box } from "@mui/material";

function PrivacyScreen() {
  return (
    <>
      <Box
        sx={{
          marginLeft: { xs: 3, md: 6, lg: 8 },
          marginRight: { xs: 3, md: 6, lg: 8 },
          marginBottom: 8,
        }}
      >
        <Typography variant="h6">PRIVACY - DAPPER AI</Typography>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">1.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Introduction</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                At Dapper AI (part of Down App, Inc.), safeguarding your privacy
                and ensuring the security of your personal information are
                paramount. This Privacy Policy outlines the information we
                collect, how we utilize and share it, and the protective
                measures we implement. Your use of our website signifies your
                agreement with the terms of this Privacy Policy.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">2.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Information We Collect</Typography>
            </Box>
            <Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Personal Information & Photos
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        When using our services or creating an account on
                        DapperAI, we may gather personal details such as your
                        name, email address, and payment information. Your
                        uploaded photos are deleted from our servers after a
                        short period of time — we do not retain them. Your
                        AI-generated photos are deleted within 30 days, so be
                        sure to download them. Additionally, we may collect
                        related information concerning the photos you upload to
                        our platform and any other details you provide, such as
                        image metadata.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Non-Personal Information
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        We may gather non-personal data about your website
                        usage, including your IP address, browser type,
                        operating system, and visited pages. This information
                        aids us in enhancing our services and understanding user
                        behavior.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Cookies and Similar Technologies
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        We are a privacy-focused team. We employ cookies and
                        similar technologies to improve your user experience and
                        gather information on your website usage, HOWEVER, we do
                        not sell your data nor help other third parties identify
                        you. You can manage cookie usage through your browser
                        settings, although disabling cookies may impact website
                        functionality.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">3.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">How We Use Your Information</Typography>
            </Box>
            <Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        To Provide and Enhance Our Services
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        We utilize your personal information to furnish
                        services, process transactions, and communicate
                        regarding your account or our services. Furthermore, we
                        employ this data to refine our website and develop new
                        features and offerings.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        For Marketing and Promotional Activities
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        Your personal information may be used to disseminate
                        promotional messages, newsletters, and other marketing
                        communications. You retain the option to opt out of
                        receiving such communications at any time.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        To Fulfill Legal Requirements
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        We may employ your personal information to adhere to
                        applicable laws, regulations, and legal proceedings, as
                        well as safeguard our rights and those of others.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">4.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Facial Feature Data Policy</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We do not permanently store facial data on our servers.
                User-provided photos, including facial data and avatar photos
                generated through artificial intelligence training, are not
                shared with third parties and are not stored on our servers
                beyond 30 days. We delete user provided images after generation
                is completed and is never shared with any 3rd party services. We
                do not rent, sell, or share your facial data, including photos
                and videos, with any third parties. When a user deletes an
                account all data is deleted including generated images and
                model.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">5.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                How We Share Your Information
              </Typography>
            </Box>
            <Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        With Third-Party Service Providers
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        We use minimal third-party services and no services that
                        are meant to track you. Still, personal information may
                        be shared with third-party service providers assisting
                        in business operations, service provision, and
                        communication. These providers are obligated to protect
                        your information and utilize it solely for its intended
                        purposes.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        In Business Transfer Scenarios
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        In the event of mergers, acquisitions, reorganizations,
                        or asset sales, personal information may be transferred
                        as part of the transaction.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        For Legal Compliance
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        Personal information may be disclosed if required by
                        law, legal processes, or to protect rights, property, or
                        safety.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">6.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Data Retention and Security</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We retain personal information as necessary to provide services,
                comply with legal obligations, resolve disputes, and enforce
                agreements. Measures are taken to safeguard personal information
                from unauthorized access, use, or disclosure, although absolute
                security cannot be guaranteed.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">7.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">International Data Transfers</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Personal information may be transferred to countries outside
                Latvia, including those with differing data protection laws.
                Steps are taken to ensure secure treatment of personal
                information, irrespective of transfer location.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">8.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Compliance with GDPR and Latvian Privacy Laws
              </Typography>
            </Box>
            <Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Lawful Processing Basis
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        Personal information processing adheres to the GDPR and
                        Latvian privacy laws, with processing lawful based on
                        service provision, website enhancement, and legal
                        compliance.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Data Subject Rights under GDPR
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        Residents of the European Economic Area (EEA) have GDPR
                        rights, including access, update, or deletion of
                        personal information, objection to processing, data
                        portability, and withdrawal of consent. Contact us to
                        exercise these rights.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Data Protection Authority Contact
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        For concerns regarding data processing, contact us
                        first. If unresolved, complaints may be lodged with the
                        Latvian Data Protection Authority or other EEA
                        supervisory authorities.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">9.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Your Rights and Choices</Typography>
            </Box>
            <Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>a.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Accessing, Updating, or Deleting Personal Information
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        You have the right to access, update, or delete personal
                        information at any time. Contact us to exercise these
                        rights.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>b.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Opting Out of Marketing Communications
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        To cease receiving marketing communications, follow
                        unsubscribe instructions in messages or contact us
                        directly.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex", marginTop: 1 }}>
                  <Box sx={{ marginRight: 3 }}>
                    <Typography>c.</Typography>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <Box>
                      <Typography align="justify">
                        Cookies and Tracking Technologies
                      </Typography>
                    </Box>
                    <Box>
                      <Typography align="justify">
                        Manage cookie and tracking technology usage via browser
                        settings, acknowledging potential impacts on website
                        functionality.
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 5 }}>
            <Typography variant="h6">10.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Third-Party Links and Services
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We are not responsible for the privacy practices of third-party
                websites and services. Prior to providing personal information,
                review their privacy policies.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">10.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Children’s Privacy</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                Our services are intended for users aged 18 and above. We do not
                knowingly collect, use, or disclose personal information from
                children under 18. Contact us if you believe personal
                information of a child has been inadvertently collected.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">12.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">
                Changes to This Privacy Policy
              </Typography>
            </Box>
            <Box>
              <Typography align="justify">
                We reserve the right to update this Privacy Policy. Notification
                of changes will be provided through our website or other
                suitable means. Continued use of our website or services
                constitutes acceptance of changes.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography variant="h6">13.</Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Box>
              <Typography variant="h6">Contact Information</Typography>
            </Box>
            <Box>
              <Typography align="justify">
                For queries or concerns about this Privacy Policy, our website,
                or services, contact us at{" "}
                <a
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                  href="mailto:human@dapper-ai.com"
                >
                  human@dapper-ai.com.
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginTop: 3 }}>
          <Box sx={{ marginRight: 3 }}>
            <Typography>Effective date: Feb 14, 2024</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PrivacyScreen;
